<template>
  <b-card>
    <b-row class="mb-2">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="type">Status:</label>
          <v-select
            id="type"
            label="name"
            v-model="filter.type"
            :options="statues"
            placeholder="-- Pilih --"
            :reduce="(option) => option.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start_date_time">Start Time:</label>
          <flat-pickr
            id="start_date_time"
            v-model="filter.start_date_time"
            class="form-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
              time_24hr: true,
            }"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="end_date_time">End Time:</label>
          <flat-pickr
            id="end_date_time"
            v-model="filter.end_date_time"
            class="form-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
              time_24hr: true,
            }"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :logs="logs"
      :is-loading="isLoading"
      :get-data="getData"
      :current-page="currentPage"
    />
  </b-card>
</template>

<script>
import Table from "@/components/comarch/Table.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      logs: [],
      filter: {},
      statues: [
        { value: "error", name: "Error" },
        { value: "success", name: "Success" },
      ],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get("/api/v1/admin/mongo-logging/comarch", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.logs = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
